import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { AnimationProgressCallback } from '@visual-elements/location-map';
import { RootState } from '../../store';
import { startAppListening } from '../../listenerMiddleware';

export type AnimationState = {
  isPlaying: boolean;
  playbackIsAtEnd: boolean;
  currentKeyFrameIndex: number | undefined;
  keyFrames: number;
  viewEnabled: boolean;
};

const initialState: AnimationState = {
  playbackIsAtEnd: false,
  isPlaying: false,
  currentKeyFrameIndex: undefined,
  keyFrames: 0,
  viewEnabled: false
};

const animationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    toggleView(state, action: PayloadAction<{ action: 'off' | 'on' | 'toggle' }>) {
      if (action.payload.action === 'on') state.viewEnabled = true;
      else if (action.payload.action === 'off') state.viewEnabled = false;
      else if (action.payload.action === 'toggle') {
        state.viewEnabled = !state.viewEnabled;
      }
    },
    updateAnimationProgress(state, action: PayloadAction<Parameters<AnimationProgressCallback>[0]>) {
      state.currentKeyFrameIndex = action.payload.keyframeIndex;
      if (action.payload.isEnd) {
        state.currentKeyFrameIndex++;
        state.playbackIsAtEnd = true;
        state.isPlaying = false;
      } else {
        state.playbackIsAtEnd = false;
      }
    },
    addKeyFrame(
      state,
      action: PayloadAction<{ type: 'index'; index: number } | { type: 'action'; at: 'start' | 'end' }>
    ) {
      const index =
        action.payload.type === 'action' ? (action.payload.at === 'end' ? state.keyFrames : 0) : action.payload.index;

      state.currentKeyFrameIndex = index;
      state.keyFrames++;
    },
    removeKeyFrame(state) {
      if (state.keyFrames <= 0) throw new Error('Can not remove keyframes when there are none');
      if (!state.currentKeyFrameIndex) state.currentKeyFrameIndex = state.keyFrames - 1;
      else {
        if (state.keyFrames === 1) state.currentKeyFrameIndex === undefined;
        else if (state.keyFrames - 1 <= state.currentKeyFrameIndex) {
          state.currentKeyFrameIndex--;
        }
      }

      state.keyFrames--;
    },
    setKeyFrames(state, action: PayloadAction<{ amount: number }>) {
      state.keyFrames = action.payload.amount;
      state.currentKeyFrameIndex = 0;
    },
    playAnimation(state) {
      state.isPlaying = true;
    },
    pauseAnimation(state) {
      state.isPlaying = false;
    },
    stepCurrentKeyFrame(state, action: PayloadAction<{ direction: 'forward' | 'backward' }>) {
      if (state.currentKeyFrameIndex === undefined) return;
      if (action.payload.direction === 'forward' && state.keyFrames - 1 > state.currentKeyFrameIndex) {
        state.currentKeyFrameIndex++;
      } else if (action.payload.direction === 'backward' && state.currentKeyFrameIndex > 0) {
        state.currentKeyFrameIndex--;
      }
    },
    setCurrentKeyframe(state, action: PayloadAction<{ index: number }>) {
      if (action.payload.index < 0) return;
      state.currentKeyFrameIndex = action.payload.index;
    }
  }
});

export const selectAnimationPlaybackState = (state: RootState) => ({
  isPlaying: state.animation.isPlaying,
  currentKeyFrame: state.animation.currentKeyFrameIndex
});

export const {
  updateAnimationProgress,
  stepCurrentKeyFrame,
  setCurrentKeyframe,
  pauseAnimation,
  playAnimation,
  toggleView,
  addKeyFrame,
  removeKeyFrame,
  setKeyFrames
} = animationSlice.actions;

startAppListening({
  matcher: isAnyOf(setCurrentKeyframe, stepCurrentKeyFrame, removeKeyFrame, setKeyFrames),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(pauseAnimation());

    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');

    const animationOptions = rootState.projectConfig.customizedOptions.animation;
    if (!animationOptions) throw new Error('Animation options should be defined');

    const currentKeyFrameIndex = rootState.animation.currentKeyFrameIndex;
    if (currentKeyFrameIndex === undefined) return;

    const animationControls = rootState.locationMapInstance.editorMapRef?.mapRef?.getAnimationControls();
    if (!animationControls) throw new Error('Animation controls should be defined');

    if (currentKeyFrameIndex === 0) {
      animationControls.seek(0);
    } else if (animationOptions.keyFrames) {
      const keyFrame = animationOptions.keyFrames[currentKeyFrameIndex - 1];
      animationControls.seek(keyFrame.time + keyFrame.duration + (keyFrame.delay ?? 0));
    }
  }
});

startAppListening({
  actionCreator: pauseAnimation,
  effect: async (action, listenerApi) => {
    const rootState = listenerApi.getState();

    const controls = rootState.locationMapInstance.editorMapRef?.mapRef?.getAnimationControls();
    const controlsPreview = rootState.locationMapInstance.previewMapRef?.mapRef?.getAnimationControls();

    if (controls) {
      controls.pause();
      controlsPreview?.pause();
    }
  }
});

startAppListening({
  actionCreator: playAnimation,
  effect: async (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');
    if (rootState.projectConfig.aggregatedOptions.animation.keyFrames.length === 0) {
      listenerApi.dispatch(pauseAnimation());
      return;
    }

    const isPublishTab = rootState.projectConfig.showWizard
      ? rootState.projectConfig.urlParam === 'publish'
      : rootState.projectConfig.tab === 'publish';

    const controls = isPublishTab
      ? rootState.locationMapInstance.previewMapRef?.mapRef?.getAnimationControls()
      : rootState.locationMapInstance.editorMapRef?.mapRef?.getAnimationControls();

    controls?.recalculateAnimation();
    if (rootState.animation.playbackIsAtEnd || isPublishTab) {
      controls?.seek(0);
    }
    controls?.play();
  }
});
export default animationSlice.reducer;
