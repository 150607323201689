import { cloneDeep } from 'lodash';
import { merge } from 'editor/core/highcharts-editor';
import { useCompanyThemeOptions } from 'pages/CompanyThemeEditorPage/middleware/themeEditorPage';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getMap as getMapSamples } from '../../../editor/core/highed.sampleman';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import ajax from '../../../utils/ajax';
import { setAction as setChartAction } from '../actions/chartEditor';
import { parseCSV } from '../utils/chartEditorDataHelper';
import { getChartConfig } from '../selectors/chartEditor';
import { geoJsonMapId } from '../utils/geoJsonHelper';
import { updateAggregated } from './ChartEditor';
import { setTemplateData } from './ChartEditorData';
import { getRandomMapData, isPointMap } from '../utils/chartEditorMapHelper';

const baseMapPath = 'https://code.highcharts.com/mapdata/';
const baseEvervizMapPath = 'https://app.everviz.com/resources/map-collection/';
//TODO: Handle this better in future if we see this grows.
const useEvervizMapCollection = {
  'countries/no/no-all': 1,
  'countries/no/no-all-all': 1,
  'countries/no/custom/no-all-svalbard-and-jan-mayen': 1,
  'countries/no/no-ag-all': 1,
  'countries/no/no-in-all': 1,
  'countries/no/no-mr-all': 1,
  'countries/no/no-no-all': 1,
  'countries/no/no-os-all': 1,
  'countries/no/no-ro-all': 1,
  'countries/no/no-td-all': 1,
  'countries/no/no-tf-all': 1,
  'countries/no/no-vi-all': 1,
  'countries/no/no-vl-all': 1,
  'countries/no/no-vt-all': 1
};

function getMapLink(map) {
  return useEvervizMapCollection[map] ? baseEvervizMapPath + map : baseMapPath + map;
}

export function downloadAndAttachMap(scriptSrc) {
  // TODO move this to a helper file for other places too
  return new Promise((resolve, reject) => {
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = scriptSrc;

    s.onload = s.onreadystatechange = function (_, isAbort) {
      if (isAbort || !s.readyState || /loaded|complete/.test(s.readyState)) {
        s.onload = s.onreadystatechange = null;
        s = undefined;

        if (!isAbort) {
          // setTimeout(callback, 0);
          resolve();
          return;
        }
      }
      reject();
    };

    document.getElementsByTagName('head')[0].appendChild(s);
  });
}

export function* setGeoJSONMap(params) {
  let { map, code, name } = params.data;

  const config = yield select(getProjectConfig);
  const templateMeta = config.templateMeta;
  const isPointMap = templateMeta?.[0]?.templateTitle === 'Point map';
  let customizedOptions = merge({}, config.customizedOptions);

  if (!code || code === '') code = 'hc-key';
  if (!name || name === '') name = 'name';

  if (map) {
    map.hccode = code;
    map.hcname = name;
  }

  if (!customizedOptions.chart) customizedOptions.chart = {};
  if (map) customizedOptions.chart.map = geoJsonMapId;
  window.Highcharts.maps[geoJsonMapId] = map;

  customizedOptions.series.forEach((s, seriesIndex) => {
    s.joinBy = templateMeta?.[0]?.templateTitle === 'Bubble' && seriesIndex === 0 ? [] : [code, code];
  });

  const csv = isPointMap ? '"Latitude";"Longitude";"Name"' : getRandomMapData(map.features, map.hccode);
  customizedOptions.data.csv = csv;

  if (!isPointMap && customizedOptions?.data?.seriesMapping) {
    customizedOptions.data.seriesMapping.forEach((series) => {
      const keys = Object.keys(series);
      keys.forEach((key) => {
        if (series[key] === 0) delete series[key];
      });
      series[map.hccode] = 0;
    });
  }
  const mappedData = {};

  map.features.forEach(({ properties }) => {
    mappedData[properties[code]] = properties[name];
  });

  let seriesAssigns = cloneDeep(config.seriesAssigns);
  seriesAssigns = (seriesAssigns ?? []).map((assign) => {
    if (assign?.labels?.linkedTo === 'hc-key' && code) {
      assign.labels.linkedTo = code;
    }
    return assign;
  });

  const dataOptions = parseCSV(csv);
  yield all([
    put(
      setChartAction({
        countryValue: {
          label: 'Custom map',
          value: 'custom'
        },
        mappedData
      })
    ),
    put(
      setProjectConfigAction({
        customizedOptions,
        changeMade: true,
        dataOptions,
        seriesAssigns
      })
    )
  ]);

  yield call(updateAggregated, true);
}

export function* loadMapCodeNameMapping(params) {
  let { map, code, name } = params.data;
  const mappedData = {};

  map.features.forEach(({ properties }) => {
    mappedData[properties[code]] = properties[name];
  });
  yield put(
    setChartAction({
      mappedData
    })
  );
}

export function* loadMap(params) {
  let { map, code } = params.data;

  const mapLink = getMapLink(map.map);
  yield call(downloadAndAttachMap, `${mapLink}.js`);
  const data = yield call(ajax.ajax, {
    url: `${mapLink}.geo.json`,
    type: 'get',
    dataType: 'json'
  });

  yield call(loadMapCodeNameMapping, {
    data: {
      map: data,
      code,
      name: 'name'
    }
  });
}

// When changing the map in the wizard dropdown
export function* setMap(params) {
  let { map, customizedOptions, skipRedraw, isTilemap, val, isCountrySection, skipMapping } = params.data;
  const { chosenWizardTemplate } = yield select(getChartConfig);
  const isNowPointMap = isPointMap(chosenWizardTemplate);

  try {
    const categoryKeys = Object.keys(Highcharts.mapDataIndex.Subcategories[val.label] || {});
    let categoryOptions = categoryKeys.map((key) => ({
      label: key,
      value: Highcharts.mapDataIndex.Subcategories[val.label][key]
    }));

    if (!customizedOptions) {
      const config = yield select(getProjectConfig);
      customizedOptions = merge({}, config.customizedOptions) ?? {};
    }

    if (customizedOptions && !customizedOptions.chart) {
      customizedOptions.chart = {};
    }

    // World option in dropdown doesnt have a highmaps map
    // Use first value found under category.
    if (!map) {
      map = {
        map: categoryOptions[0].value.replace('.js', '')
      };
    }

    customizedOptions.chart.map = map.map;
    let mappedData = {};

    let csv = '';
    if (isTilemap) {
      const tilemaps = getMapSamples('Tilemap');
      delete customizedOptions.chart.map;
      customizedOptions.data.csv = tilemaps[map.map].dataset.join('\n');
      csv = customizedOptions.data.csv;
    } else {
      const mapLink = getMapLink(map.map);
      yield call(downloadAndAttachMap, `${mapLink}.js`);

      const data = yield call(ajax.ajax, {
        url: `${mapLink}.geo.json`,
        type: 'get',
        dataType: 'json',
        stopHeaders: mapLink.includes(baseEvervizMapPath)
      });

      data.features.forEach(({ properties }) => {
        mappedData[properties['hc-key']] = properties.name;
      });

      let hcCode = 'hc-key';
      if (!isNowPointMap) {
        let dataOptions = yield call(setTemplateData, customizedOptions, chosenWizardTemplate, false, hcCode);
        customizedOptions = dataOptions.customizedOptions;
        csv = dataOptions.customizedOptions.data.csv;
      }

      if (!skipMapping) {
        let seriesMapping = cloneDeep(customizedOptions.data.seriesMapping);

        if (!isNowPointMap) {
          seriesMapping.forEach((mapping) => {
            const val = mapping['hc-key'];
            delete mapping['hc-key'];
            mapping[hcCode] = val;
          });
        }

        customizedOptions.series.forEach(function (s) {
          s.joinBy = [hcCode, hcCode];
        });
        customizedOptions.data.seriesMapping = seriesMapping;
      }
    }

    // Set dropdown values
    let setDropdownValues = {
      [isCountrySection ? 'countryValue' : 'categoryValue']: val,
      mappedData
    };

    if (!isTilemap && isCountrySection) {
      // Set category value to first value
      if (categoryOptions?.length) setDropdownValues.categoryValue = categoryOptions[0];
    }

    yield put(setChartAction(setDropdownValues));
    yield put(
      setProjectConfigAction({
        customizedOptions,
        changeMade: true,
        dataOptions: parseCSV(csv)
      })
    );

    yield call(useCompanyThemeOptions);

    if (!skipRedraw) {
      yield call(updateAggregated, true);
    }
  } catch (e) {
    console.log(e);
  }
}

export function* useGeoJSONMap() {
  const config = yield select(getProjectConfig);
  let customizedOptions = merge({}, config.customizedOptions) ?? {};

  if (customizedOptions && !customizedOptions.chart) {
    customizedOptions.chart = {};
  }
}

/** Watch functions */
export function* watchSetMap() {
  yield takeEvery(actionTypes.chartEditor.setMap, setMap);
}
export function* watchUseGeoJSONMap() {
  yield takeEvery(actionTypes.chartEditor.useGeoJSONMap, useGeoJSONMap);
}
export function* watchSetGeoJSONMap() {
  yield takeEvery(actionTypes.chartEditor.setGeoJSONMap, setGeoJSONMap);
}

export default function* rootSaga() {
  yield all([watchSetMap(), watchUseGeoJSONMap(), watchSetGeoJSONMap()]);
}
