import { isNull, merge } from 'editor/core/highcharts-editor';
import { cloneDeep } from 'lodash';
import EditorPublish from 'pages/ChartEditorPage/components/EditorPublish';
import {
  closePublishModalAction,
  openPublishModalAction,
  resetStateAction,
  toggleCMSModalAction
} from 'pages/LayoutEditorPage/actions/layoutEditor';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
  closeWizardAction,
  resetProjectConfigAction,
  setAction as setProjectConfigAction
} from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import OptionsPanelContainer from 'shared/editor/generic/OptionsPanelContainer';
import ProjectContainer from 'shared/editor/generic/ProjectContainer';
import WorkspaceBar from 'shared/editor/generic/WorkspaceBar';
import OptionsPanel, { PanelOptionProps } from '../../shared/editor/generic/OptionsPanel';
import Wizard from '../../shared/editor/generic/Wizard';
import CMSModal from '../../shared/modal/CMSModal';
import { useIsMobile } from '../../shared/utils/mediaHelper';
import GlobalModals from '../../shared/wizard/editor/GlobalModals';
import './../../../node_modules/react-quill/dist/quill.snow.css';
import {
  closeExportModalAction,
  filterDataAction,
  initEditorAction,
  loadTableAction,
  openExportModalAction,
  resetTableStateAction,
  setAction,
  setDataAction
} from './actions/tableEditor';
import ExportModal from './components/ExportModal';
import TableComponent from './components/TableComponent';
import RichTextEditorToolbar from './containers/RichTextEditorToolbar';
import CustomizeOptions from './meta/CustomizeOptions';
import PanelOptions from './meta/PanelOptions';
import WizardOptions from './meta/wizard/WizardOptions';
import LiveDataMessage from 'shared/message/LiveDataMessage';
import { getIsLiveDataSource } from './../../shared/wizard/utils/seriesHelper';
import { onSave } from 'shared/editor/publishHelper';

type ModalsKeyTypes = 'wizard' | 'publish' | 'export';
type ActionTypes = 'closeWizard' | 'closePublishModal' | 'openPublishModal' | 'closeExportModal' | 'openExportModal';
const modals: Record<ModalsKeyTypes, { close: ActionTypes; open: ActionTypes }> = {
  wizard: {
    close: 'closeWizard',
    open: 'closeWizard'
  },
  publish: {
    close: 'closePublishModal',
    open: 'openPublishModal'
  },
  export: {
    close: 'closeExportModal',
    open: 'openExportModal'
  }
};

type TableEditorProps = {
  location: RouteComponentProps['location'];
  match: RouteComponentProps['match'];
};

type ParamsProps = { tableid?: string };

export default function TableEditor(props: TableEditorProps) {
  const { location, match } = props;
  const { showWizard, dataConfig, changeMade, dataOptions, customizedOptions, themeOptions, type } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { cmsModalOpen } = useSelector((state: RootState) => state.layoutEditorPage);
  const { activeCellColumn, activeCellRow, inSelectMode, showExportModal } = useSelector(
    (state: RootState) => state.tableEditorPage
  );
  const { team } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const params = match.params as ParamsProps;
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [wizardOptions, setWizardOptions] = useState(WizardOptions);
  const [panelOptions] = useState<Array<PanelOptionProps>>(cloneDeep(PanelOptions as any as PanelOptionProps[]));

  const aggregatedOptions = merge(
    merge({}, themeOptions.options, null, {}),
    merge({}, customizedOptions, null, {}),
    null,
    {}
  );
  const isLiveDataSource = getIsLiveDataSource(aggregatedOptions, dataConfig);

  const loadTable = (team: { id: number }, storyId: number, cb: () => void) =>
    dispatch(loadTableAction({ team, storyId, cb }));
  const setData = (dataOptions: any, customizedOptions: any, options: any) => {
    dispatch(setDataAction({ dataOptions, customizedOptions, paginate: null, dataType: 'google', options }));
  };
  const initEditor = (newTable: boolean, urlParams: ParamsProps, query: qs.ParsedQs) =>
    dispatch(initEditorAction({ newTable, urlParams, query }));
  const filterData = () => dispatch(filterDataAction({}));
  const toggleCMSModal = (toggle: boolean) => dispatch(toggleCMSModalAction({ toggle }));

  const setProjectConfig = (params: any) => dispatch(setProjectConfigAction(params));
  const setTableAction = (params: RootState['tableEditorPage']) => dispatch(setAction(params));

  const modalsMap: Record<ActionTypes, any> = {
    closeWizard: (params: any) => {
      onSave(type, false, dispatch);
      dispatch(closeWizardAction(params));
    },
    closePublishModal: () => dispatch(closePublishModalAction({})),
    openPublishModal: () => dispatch(openPublishModalAction({})),
    closeExportModal: () => dispatch(closeExportModalAction({})),
    openExportModal: () => dispatch(openExportModalAction({}))
  };

  const resetState = () => {
    dispatch(resetStateAction({}));
    dispatch(resetTableStateAction({}));
    dispatch(resetProjectConfigAction({}));
  };

  useEffect(() => {
    let newTable = true;
    if (params.tableid && parseInt(params.tableid, 10)) {
      loadTable(team, parseInt(params.tableid, 10), () => {
        if (dataConfig?.googleSpreadsheetKey) {
          setData([], customizedOptions, dataConfig);
        }
      });
      newTable = false;
    }

    initEditor(newTable, params, query);

    return () => {
      if (location.pathname.match('(.edit.)([-0-9]+)') === null) {
        resetState();
      }
    };
  }, []);

  const closeModal = (type: ModalsKeyTypes) => {
    const closeKey = modals[type].close;
    modalsMap[closeKey]();
  };
  const openModal = (type: ModalsKeyTypes) => {
    const openKey = modals[type].open;
    modalsMap[openKey]();
  };

  const check = (location: RouteComponentProps['location']) => {
    return location.pathname.indexOf('table') === -1 && changeMade
      ? 'Leaving this page will result in unsaved changes being deleted. Are you sure you want to continue?'
      : true;
  };

  const closeWizard = (type: ModalsKeyTypes) => {
    // Wizard uses seriesAssigns to filter out data.
    // We dont use this in the editor though, do a final filter to fix dataOptions before going into the editor.
    filterData();
    const closeKey = modals[type].close;
    modalsMap[closeKey]();
  };

  const closeCMSModal = () => toggleCMSModal(false);

  const onMouseUp = () => {
    if (inSelectMode) {
      setTableAction({
        inSelectMode: false
      });
    }
  };

  const goBackToWizard = () => {
    setWizardOptions(WizardOptions.filter((d) => d.header !== 'Title Your Table'));
    setProjectConfig({
      showWizard: true
    });
  };

  return (
    <div className="story table-editor flex flex-wrap" onMouseUp={onMouseUp}>
      <Prompt when={true} message={check} />
      <ExportModal
        close={closeModal}
        aggregatedOptions={aggregatedOptions}
        isOpen={showExportModal}
        dataOptions={dataOptions}
      />
      <CMSModal open={cmsModalOpen} close={closeCMSModal} />
      {(location.pathname === '/table/create' || location.pathname.startsWith('/table/edit/')) && !!showWizard ? (
        <Wizard close={closeWizard} wizardOptions={wizardOptions} isMap={false} />
      ) : (
        <>
          {isMobile && <EditorPublish />}
          <WorkspaceBar panelOptions={panelOptions} />
        </>
      )}

      <div className="flex w-full flex-col ev-sm:flex-row">
        <OptionsPanelContainer>
          <OptionsPanel options={aggregatedOptions} panelOptions={panelOptions} customizeOptions={CustomizeOptions} />
        </OptionsPanelContainer>

        <ProjectContainer
          topBar={
            <>
              {isNull(activeCellColumn) && isNull(activeCellRow) && (
                <RichTextEditorToolbar showWizard={goBackToWizard} showExportModal={openModal} />
              )}
              {!isNull(activeCellColumn) && !isNull(activeCellRow) && (
                <RichTextEditorToolbar showWizard={goBackToWizard} showExportModal={openModal} />
              )}
            </>
          }
        >
          {isLiveDataSource && <LiveDataMessage />}
          <TableComponent />
        </ProjectContainer>
      </div>
      <GlobalModals />
    </div>
  );
}
