import { all, put, select, takeEvery } from 'redux-saga/effects';
import { loadMapCSVAction } from '../actions/chartEditor';
import { parseCSV } from '../utils/chartEditorDataHelper';
import actionTypes from 'redux/actions/action-types';
import { LocatorResult } from '../../../editor/core/highed.mapapi';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';

export type GenericPayload = {
  type: string;
};

export type AddPointPayload = GenericPayload & {
  data: { location: LocatorResult; disableFitMapView?: boolean };
};

export type DeletePointPayload = GenericPayload & {
  data: { index: number };
};
function* deletePoint(params: DeletePointPayload) {
  const { index } = params.data;
  const { aggregatedOptions } = yield select((state) => state.projectConfig);

  if (aggregatedOptions?.data?.csv) {
    const parsedCSV = parseCSV(aggregatedOptions.data.csv, null, null);
    if (parsedCSV) {
      parsedCSV.splice(index + 1, 1);
      yield put(loadMapCSVAction({ options: parsedCSV, skipMapping: true }));
    }

    yield put(
      setProjectConfigAction({
        changeMade: true
      })
    );
  }
}

function* addPoint(params: AddPointPayload) {
  const { location } = params.data;
  const { aggregatedOptions } = yield select((state) => state.projectConfig);

  let parsedCSV = [];
  if (aggregatedOptions?.data?.csv) parsedCSV = parseCSV(aggregatedOptions?.data?.csv, null, null);
  else parsedCSV.push(['Latitude', 'Longitude', 'Name']);

  if (parsedCSV) {
    parsedCSV.push([parseFloat(location.lat), parseFloat(location.lon), location.value.split(',')[0]]);
    yield put(loadMapCSVAction({ options: parsedCSV, skipMapping: true }));
  }
  yield put(
    setProjectConfigAction({
      changeMade: true
    })
  );
}

/** Watch functions */
export function* watchDeletePoint() {
  yield takeEvery(actionTypes.pointMap.deletePoint, deletePoint);
}

export function* watchAddPoint() {
  yield takeEvery(actionTypes.pointMap.addPoint, addPoint);
}

export default function* rootSaga() {
  yield all([watchDeletePoint(), watchAddPoint()]);
}
