import { cloneDeep } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from 'redux/actions/action-types';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { GenericPayload } from '../ChartEditorPointMap';
import { ProjectConfigLocationMapProps } from '../../../Editor/reducers/locationMapConfigTypes';
import { AnimationKeyFrame, AnimationOptions, AnimationViewState } from '@visual-elements/location-map';
import { updateAggregated } from '../ChartEditor';
import { addKeyFrame, AnimationState, removeKeyFrame } from '../../../../redux/reducers/locationMap/animationReducer';
import { RootState } from '../../../../redux/store';
import { LocationMapInstanceState } from '../../../../redux/reducers/locationMap/instanceReducer';

type AddAnimationKeyFramePayload = GenericPayload & {
  data: { index: number; bearing: number; zoom: number; pitch: number; center: [number, number] };
};

export function* addAnimationKeyFrame(params: AddAnimationKeyFramePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    const { editorMapRef }: LocationMapInstanceState = yield select((state) => state.locationMapInstance);

    const map = editorMapRef?.mapRef?.getMap();

    if (!map) throw new Error('Map must be defined if it has reported viewstate');

    if (!newCustomizedOptions.animation || !newCustomizedOptions.animation.enabled) {
      newCustomizedOptions.animation = {
        enabled: true,
        initialViewState: {
          bearing: params.data.bearing,
          zoom: params.data.zoom,
          pitch: params.data.pitch,
          center: params.data.center
        },
        keyFrames: [],
        setupFeatures: [],
        sideEffects: []
      };
    } else {
      newCustomizedOptions.animation.keyFrames ??= [];
      const lastCameraUpdate = newCustomizedOptions.animation.keyFrames.at(-1);

      const time = lastCameraUpdate
        ? lastCameraUpdate.time + lastCameraUpdate.duration + (lastCameraUpdate.delay ?? 0)
        : 0;

      const newKeyFrame: AnimationKeyFrame = {
        bearing: params.data.bearing,
        center: params.data.center,
        duration: 3000,
        easing: 'none',
        pitch: params.data.pitch,
        zoom: params.data.zoom,
        time: time
      };

      // TODO Optimize this to make the zoom in smoother directly to target
      const lastKeyFrame = lastCameraUpdate ? lastCameraUpdate : newCustomizedOptions.animation.initialViewState!;
      const zoomScale = lastKeyFrame.zoom - newKeyFrame.zoom;
      newKeyFrame.centerChangeDuration = 3000 / Math.log2(Math.abs(zoomScale));

      const lastBearing =
        newCustomizedOptions.animation.keyFrames.length > 0
          ? newCustomizedOptions.animation.keyFrames[newCustomizedOptions.animation.keyFrames.length - 1].bearing
          : newCustomizedOptions.animation.initialViewState!.bearing;

      const normalizedBearing = normalizeBearing(params.data.bearing, lastBearing);

      newKeyFrame.bearing = normalizedBearing;
      newCustomizedOptions.animation.keyFrames.push(newKeyFrame);
    }
    // TODO - Fix index. Also duration and time.
    yield put(addKeyFrame({ type: 'action', at: 'end' }));

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated, true);
    editorMapRef?.mapRef?.getAnimationControls().recalculateAnimation();
  } catch (err) {
    console.log(err);
  }
}

type UpdateAnimationKeyFramePayload = GenericPayload & {
  data: {
    index: number;
    viewState?: AnimationViewState;
    duration?: number;
    easing?: AnimationKeyFrame['easing'];
  };
};

export function* updateAnimationKeyFrame(params: UpdateAnimationKeyFramePayload) {
  try {
    const { editorMapRef }: LocationMapInstanceState = yield select((state) => state.locationMapInstance);
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    // One can assume this is not partial as one has to add a keyframe to update one
    const animationOptions = newCustomizedOptions.animation as AnimationOptions;

    if (params.data.index === 0) {
      if (!params.data.viewState) throw new Error('Viewstate must be defined to update the initial view state');
      animationOptions.initialViewState = {
        center: params.data.viewState.center,
        bearing: params.data.viewState.bearing,
        pitch: params.data.viewState.pitch,
        zoom: params.data.viewState.zoom
      };
    } else if (params.data.index > 0) {
      const keyFrame = animationOptions.keyFrames[params.data.index - 1];
      if (keyFrame) {
        if (params.data.duration !== undefined) {
          keyFrame.duration = params.data.duration;
        }
        if (params.data.viewState) {
          keyFrame.center = params.data.viewState.center;
          keyFrame.bearing = params.data.viewState.bearing;
          keyFrame.pitch = params.data.viewState.pitch;
          keyFrame.zoom = params.data.viewState.zoom;
        }
        if (params.data.easing) {
          keyFrame.easing = params.data.easing;
        }
      }
      recalculateKeyFrameTimings(animationOptions);
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated, true);
    editorMapRef?.mapRef?.getAnimationControls().recalculateAnimation();
  } catch (err) {
    console.log(err);
  }
}

function recalculateKeyFrameTimings(animationOptions: AnimationOptions) {
  if (animationOptions.keyFrames.length <= 0) return;
  for (let i = 0; i < animationOptions.keyFrames.length; i++) {
    const currentKeyFrame = animationOptions.keyFrames[i];
    if (i === 0) {
      currentKeyFrame.time = 0;
    } else {
      const lastKeyFrame = animationOptions.keyFrames[i - 1];
      currentKeyFrame.time = lastKeyFrame.time + lastKeyFrame.duration + (lastKeyFrame.delay ?? 0);
    }
  }
}

function normalizeBearing(bearing: number, currentBearing: number) {
  bearing = wrap(bearing, -180, 180);
  const diff = Math.abs(bearing - currentBearing);
  if (Math.abs(bearing - 360 - currentBearing) < diff) bearing -= 360;
  if (Math.abs(bearing + 360 - currentBearing) < diff) bearing += 360;
  return bearing;
}

/**
 * constrain n to the given range, excluding the minimum, via modular arithmetic
 *
 * @param n - value
 * @param min - the minimum value to be returned, exclusive
 * @param max - the maximum value to be returned, inclusive
 * @returns constrained number
 */
export function wrap(n: number, min: number, max: number): number {
  const d = max - min;
  const w = ((((n - min) % d) + d) % d) + min;
  return w === min ? max : w;
}

type RemoveAnimationKeyFramePayload = GenericPayload & {
  data: { index: number };
};

export function* removeAnimationKeyFrame(params: RemoveAnimationKeyFramePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const { editorMapRef }: LocationMapInstanceState = yield select((state) => state.locationMapInstance);

    const { currentKeyFrameIndex }: AnimationState = yield select(
      (state: RootState) => state.animation.currentKeyFrameIndex
    );
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    // One can assume this is not partial as one has to add a keyframe to remove one
    const animationOptions = newCustomizedOptions.animation as AnimationOptions;

    if (params.data.index === 0) {
      if (animationOptions.keyFrames.length === 0) {
        newCustomizedOptions.animation = { enabled: false };
      } else {
        animationOptions.initialViewState = {
          bearing: animationOptions.keyFrames[0].bearing,
          center: animationOptions.keyFrames[0].center,
          pitch: animationOptions.keyFrames[0].pitch,
          zoom: animationOptions.keyFrames[0].zoom
        };
        animationOptions.keyFrames.splice(0, 1);
      }
    } else {
      animationOptions.keyFrames.splice(params.data.index - 1, 1);
      recalculateKeyFrameTimings(animationOptions);

      if (currentKeyFrameIndex === params.data.index) {
        const lastIndex = animationOptions.keyFrames.length - 1;

        let newSelectedKeyFrameViewState: AnimationViewState;
        if (lastIndex === 0) {
          newSelectedKeyFrameViewState = animationOptions.initialViewState;
        } else {
          newSelectedKeyFrameViewState = animationOptions.keyFrames[currentKeyFrameIndex - 1];
        }
        editorMapRef?.mapRef?.getMap()?.jumpTo({
          ...newSelectedKeyFrameViewState
        });
      }
    }
    yield put(removeKeyFrame());

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated, true);
    editorMapRef?.mapRef?.getAnimationControls().recalculateAnimation();
  } catch (err) {
    console.log(err);
  }
}

export function* watchRemoveAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.removeAnimationKeyFrame, removeAnimationKeyFrame);
}

export function* watchAddAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.addAnimationKeyFrame, addAnimationKeyFrame);
}

export function* watchUpdateAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.updateAnimationKeyFrame, updateAnimationKeyFrame);
}

export default function* rootSaga() {
  yield all([watchAddAnimationKeyFrame(), watchRemoveAnimationKeyFrame(), watchUpdateAnimationKeyFrame()]);
}
