/* eslint-disable no-new */
/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
import { cloneDeep } from 'lodash';
import { loadActiveCompanyTheme } from 'pages/CompanyThemeEditorPage/middleware/themeEditorPage';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getTeamTableWithTeamidAndTableid,
  postTeamTableEmbedUsingTeamidAndTableid,
  postTeamTableUsingTeamid,
  postTeamTableUsingTeamidAndTableid
} from '../../../api/cloudApiGenerated';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProfileAction } from '../../../redux/actions/profile';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getProfileConfig } from '../../../redux/selectors/profile';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setAction } from '../../LayoutEditorPage/actions/layoutEditor';
import { loadCSS } from '../../LayoutEditorPage/utils/storyHelper';
import { loadPaginatedAction, setAction as setTableAction } from '../actions/tableEditor';
import DefaultThemes from '../meta/DefaultThemes';
import { getConfig, getTableSpecificConfig } from '../selectors/tableEditor';
import { getConfig as getLayoutConfig } from 'pages/TableEditorPage/selectors/tableEditor';
import { updateBufferData } from './tableEditorData';
import { assignTheme } from './tableEditorThemes';
import { merge } from 'editor/core/highcharts-editor';
import { snackBar } from 'editor/editors/highed.init';
import { getCompanyThemes } from 'middleware/editor/ProjectEditor';

export function* initEditor(params) {
  let { newTable, urlParams, query } = params.data;
  const projectConfig = yield select(getProjectConfig);
  const { defaultDataOptions, defaultAssignRawOptions, defaultCustomizeOptions } = yield select(getTableSpecificConfig);
  let customizedOptions = cloneDeep(projectConfig.customizedOptions);

  let projectConfigOptions = {
    showWizard: location.pathname.match('/create')
  };

  Highcharts.setOptions({
    stockTools: {
      gui: {
        enabled: false
      }
    }
  });

  if (Highcharts) {
    Highcharts.setOptions({
      plotOptions: {
        series: {
          label: {
            enabled: false
          }
        }
      }
    });
  }

  const setValues = {
    storyId: urlParams.tableid && parseInt(urlParams.tableid, 10)
  };

  if (newTable) {
    projectConfigOptions.customizedOptions = merge(cloneDeep(defaultCustomizeOptions), customizedOptions);
    projectConfigOptions.dataOptions = cloneDeep(defaultDataOptions);
    projectConfigOptions.seriesAssigns = cloneDeep(defaultAssignRawOptions);
  }
  if (query && 'cms' in query) setValues.cmsModalOpen = true;

  projectConfigOptions.type = 'table';

  yield all([put(setProjectConfigAction(projectConfigOptions)), put(setAction(setValues))]);

  if (newTable) {
    const hasCompanyTheme = yield call(loadActiveCompanyTheme, 'table');
    if (!hasCompanyTheme) {
      yield call(assignTheme, {
        data: {
          theme: DefaultThemes[0]
        }
      });
    }

    yield call(updateBufferData, {
      dataOptions: projectConfigOptions.dataOptions
    });
  }
}

export function* saveTable(params) {
  let { callback, hideNotification } = params.data;
  const config = yield select(getProfileConfig);
  const layoutConfig = yield select(getConfig);
  const projectConfig = yield select(getProjectConfig);

  let { storyId } = layoutConfig;
  let { division, team } = config;
  let {
    customizedOptions,
    dataOptions,
    templateOptions,
    themeOptions,
    themeMeta,
    projectName,
    cssModules,
    dataConfig
  } = projectConfig;

  let project = {
    options: customizedOptions,
    theme: themeOptions,
    themeMeta,
    template: templateOptions,
    data: {
      ...dataConfig,
      value: dataOptions
    },
    cssModules
  };

  if (dataConfig && dataConfig.googleSpreadsheetKey) {
    delete project.data.value;
  }

  let titleValue = projectName;
  if (!projectName) {
    if (project?.options?.title?.text) {
      titleValue = project?.options?.title?.text;
    } else titleValue = 'Untitled table';
  }

  const body = { data: JSON.stringify(project) };
  body.name = titleValue;

  let save;
  let parameters = [];

  if (!storyId) {
    if (division && division.id !== null) body.division_id = division.id;
    save = postTeamTableUsingTeamid;
    parameters = [team.id, body];
  } else {
    save = postTeamTableUsingTeamidAndTableid;
    parameters = [team.id, storyId, body];
  }

  let data = false;
  try {
    data = yield call(save, ...parameters);
  } catch (error) {
    if (error && error.response && error.response.message && error.response.message[0] === 'Could not create chart') {
      snackBar('Sorry, you have run out of projects');
    }
    return error;
  }

  if (!storyId) {
    yield put(
      setProfileAction({
        chartsCreated: config.chartsCreated + 1
      })
    );
  }

  if (!hideNotification) snackBar('Table saved');

  yield put(
    setProjectConfigAction({
      changeMade: false,
      lastSaved: new Date()
    })
  );

  if (storyId) {
    if (callback) {
      return callback({
        ...data,
        table_id: storyId
      });
    }
  } else {
    yield all([
      put(
        setAction({
          storyId: data.table_id
        })
      ),
      put(
        setProjectConfigAction({
          saved: true,
          uuid: data.uuid,
          lastSaved: new Date()
        })
      )
    ]);

    if (callback) return callback(data);
  }
}

export function* publishTable(params) {
  let { inspired } = params.data;
  let postEmbed;

  const profileConfig = yield select(getProfileConfig);
  const { showWizard } = yield select(getProjectConfig);
  const layoutConfig = yield select(getConfig);
  let { storyId } = layoutConfig;
  let { team } = profileConfig;

  const projectConfigOptions = {
    publishLoading: true
  };

  if (!showWizard) projectConfigOptions.tab = 'publish';
  yield put(setProjectConfigAction(projectConfigOptions));

  if (storyId) {
    postEmbed = postTeamTableEmbedUsingTeamidAndTableid;
  } else return;

  let data = yield call(postEmbed, team.id, storyId, {
    inspired
  });

  let embedDetails = {};
  embedDetails.injectCode = data.table;
  embedDetails.iframeCode = data.tableIframe;
  embedDetails.shareUrl = window.hcconfig.backend.hostname + '/share/' + data.uuid;
  embedDetails.socialLinks = window.hcconfig.backend.hostname + '/share/' + data.uuid;
  embedDetails.iframeURL = data.tableURL.replace('inject', 'embed');
  embedDetails.uuid = data.uuid;

  yield put(
    setProjectConfigAction({
      published: true,
      publishLoading: false,
      embedDetails,
      lastPublishTime: data.last_publish_time
    })
  );
}

export function* loadTable(params) {
  const { team, cb } = params.data;
  const tableConfig = yield select(getLayoutConfig);

  if (team) {
    const storyId = params?.data?.storyId ?? tableConfig.storyId;
    const data = yield call(getTeamTableWithTeamidAndTableid, team.id, storyId);
    const project = JSON.parse(data.data);
    const embedDetails = {};
    let isGSheet = false;
    if (data.injectCode) embedDetails.injectCode = data.injectCode;
    if (data.embedCode) embedDetails.iframeCode = data.embedCode;

    embedDetails.shareUrl = window.hcconfig.backend.hostname + '/share/' + data.uuid;
    embedDetails.socialLinks = window.hcconfig.backend.hostname + '/share/' + data.uuid;
    embedDetails.iframeURL = data.iframeURL;
    embedDetails.uuid = data.uuid;

    if (project.options && project.options.table) {
      let newPagination = {};
      let newSortable = {};

      // eslint-disable-next-line eqeqeq
      if (project.options.table.pagination == true || project.options.table.pagination == false) {
        newPagination.enabled = project.options.table.pagination;

        if (project.options.table.resultsPerPage) newPagination.resultsPerPage = project.options.table.resultsPerPage;
        project.options.table.pagination = newPagination;
      }

      // eslint-disable-next-line eqeqeq
      if (project.options.table.sortable == true || project.options.table.sortable == false) {
        newSortable.enabled = project.options.table.sortable;
        project.options.table.sortable = newSortable;
      }
    }

    let projectConfig = {};
    if (project?.data?.googleSpreadsheetKey) {
      projectConfig.dataConfig = project.data;
      isGSheet = true;
    }

    const table = project?.options?.table;

    if (table?.noData?.placeholderText && table?.searchable?.enabled !== false) {
      const searchable = table.searchable || { enabled: true };
      searchable.noDataText = table.noData.placeholderText;
      table.searchable = searchable;
      delete table.noData;
    }

    yield all([
      put(
        setProjectConfigAction({
          customizedOptions: project.options,
          themeOptions: project.theme,
          themeMeta: project.themeMeta,
          templateOptions: project.template,
          dataOptions: project.data?.value || [],
          embedDetails,
          uuid: data.uuid,
          changeMade: false,
          lastPublishTime: data.last_publish_time,
          published: data.has_published,
          saved: true,
          lastSaved: data.creation_time,
          cssModules: project.cssModules,
          projectName: data.name,
          ...projectConfig
        })
      ),
      put(
        setTableAction({
          isGSheet
        })
      )
    ]);

    yield call(getCompanyThemes);

    yield call(updateBufferData, {
      dataOptions: project.data?.value,
      customizedOptions: project.options
    });

    if (project.cssModules && project.cssModules.length) {
      project.cssModules.forEach((module) => {
        loadCSS(module);
      });
    }

    if (cb) {
      return cb();
    }
  }
}

// This doesnt look like it does anything
export function* loadPaginated() {
  // let { aggregatedOptions } = params.data;
  const config = yield select(getProjectConfig);
  let { dataOptions } = config;
  /*
  let range = 10;
  if (aggregatedOptions?.table?.pagination && aggregatedOptions.table.pagination.hasOwnProperty('resultsPerPage')) {
    range = aggregatedOptions.table.pagination.resultsPerPage;
  }*/
  yield call(updateBufferData, {
    dataOptions
  });
}

export function* goToNextPage(params) {
  let { aggregatedOptions, currentPage, dataOptions } = params.data;
  yield put(
    setTableAction({
      page: currentPage + 1
    })
  );

  yield put(
    loadPaginatedAction({
      dataOptions,
      aggregatedOptions,
      currentPage: currentPage + 1
    })
  );
}

export function* goToPreviousPage(params) {
  let { aggregatedOptions, currentPage, dataOptions } = params.data;

  yield put(
    setTableAction({
      page: currentPage - 1
    })
  );

  yield put(
    loadPaginatedAction({
      dataOptions,
      aggregatedOptions,
      currentPage: currentPage - 1
    })
  );
}

export function* search(params) {
  let { query, dataOptions } = params.data;
  yield put(
    setTableAction({
      searchValue: query
    })
  );

  yield call(updateBufferData, {
    dataOptions,
    searchValue: query.toLowerCase()
  });
  yield put(
    setTableAction({
      // bufferDataOptions: searchResults,
      page: 0
    })
  );
}

function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function* processConditionalFormatting(params) {
  let { index, rule, value, color } = params.data;
  const { customizedOptions } = yield select(getProjectConfig);

  let newCustomizedOptions = cloneDeep(customizedOptions);
  newCustomizedOptions.columns[index] = merge(newCustomizedOptions.columns[index], {
    rule,
    value,
    color
  });

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions
    })
  );
}

export function* addChartToTable(params) {
  let { index } = params.data;
  const { customizedOptions } = yield select(getProjectConfig);

  let newCustomizedOptions = cloneDeep(customizedOptions);

  if (index === 0) {
    newCustomizedOptions.columns.splice(1, 0, {
      type: 'chart',
      chartType: 'bar',
      hideOriginalColumns: false,
      columns: [0],
      columnName: 'Chart Column'
    });
  } else {
    newCustomizedOptions.columns.splice(1, 0, {
      type: 'chart',
      chartType: 'bar',
      hideOriginalColumns: false,
      columns: [0],
      columnName: 'Chart Column'
    });
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {});
}

export function* updateCustomizedArrayProp(params) {
  let { parent, index, val, moveObj } = params.data;
  const { customizedOptions } = yield select(getProjectConfig);
  let newCustomizedOptions = cloneDeep(customizedOptions);
  newCustomizedOptions[parent][index] = merge(newCustomizedOptions[parent][index], val);

  if (moveObj) {
    newCustomizedOptions.columns = arrayMove(
      newCustomizedOptions.columns,
      index,
      val.columns[val.columns.length - 1] + 1
    );
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    customizedOptions: newCustomizedOptions
  });
}

export function* updateChartHeader(params) {
  let { customizedOptions, index, value } = params.data;
  let newCustomizedOptions = cloneDeep(customizedOptions);
  newCustomizedOptions.columns[index].columnName = value;

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    destroyCharts: false
  });
}

export function* updateColumnHiddenValue(params) {
  let { columns, value, index } = params.data;
  const { customizedOptions } = yield select(getProjectConfig);
  let newCustomizedOptions = cloneDeep(customizedOptions);
  let parentIndex = columns?.length > 0 ? columns[0] : null;
  if (parentIndex !== null && newCustomizedOptions?.columns) {
    newCustomizedOptions.columns[index].hideOriginalColumns = !value;

    let updatableIndex = -1;
    for (let i = 0; i < newCustomizedOptions.columns.length; i++) {
      if (newCustomizedOptions.columns[i].type === 'data') {
        updatableIndex++;
      }
      if (updatableIndex === parentIndex) {
        newCustomizedOptions.columns[i].visible = value;
        break;
      }
    }
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    customizedOptions: newCustomizedOptions
  });
}

export function* showContextMenu(params) {
  let { x, y, column, row } = params.data;

  const padding = 10;

  const maxHeight = document.body.scrollHeight - 181 - padding,
    maxWidth = document.body.scrollWidth - 175 - padding;

  yield put(
    setTableAction({
      showContextMenu: true,
      contextMenuPos: [Math.min(x, maxWidth) + 'px', Math.min(y, maxHeight) + 'px'],
      contextMenuCell: [column, row]
    })
  );
}

export function* hideContextMenu() {
  yield put(
    setTableAction({
      showContextMenu: false,
      contextMenuPos: null,
      contextMenuCell: null
    })
  );
}

// Used for live preview in wizard only
export function* redrawTable() {
  const config = yield select(getProjectConfig);
  const aggregatedOptions = merge(merge({}, config.themeOptions.options), merge({}, config.customizedOptions));

  console.log(config.dataOptions, config);
  // eslint-disable-next-line no-undef
  new EvervizTable('highcharts-container', {
    options: aggregatedOptions,
    data: {
      value: config.dataOptions
    }
  });
}

/** Watch functions */
export function* watchLoadInitialPaginatedAction() {
  yield takeEvery(actionTypes.tableEditor.loadPaginated, loadPaginated);
}
export function* watchGoToNextPage() {
  yield takeEvery(actionTypes.tableEditor.goToNextPage, goToNextPage);
}
export function* watchGoToPreviousPage() {
  yield takeEvery(actionTypes.tableEditor.goToPreviousPage, goToPreviousPage);
}
export function* watchSearch() {
  yield takeEvery(actionTypes.tableEditor.search, search);
}
export function* watchProcessConditionalFormatting() {
  yield takeEvery(actionTypes.tableEditor.processConditionalFormatting, processConditionalFormatting);
}
export function* watchSaveTable() {
  yield takeEvery(actionTypes.tableEditor.saveTable, saveTable);
}
export function* watchLoadTable() {
  yield takeEvery(actionTypes.tableEditor.loadTable, loadTable);
}
export function* watchUpdateCustomizedArrayProp() {
  yield takeEvery(actionTypes.tableEditor.updateCustomizedArrayProp, updateCustomizedArrayProp);
}
export function* watchAddChartToTable() {
  yield takeEvery(actionTypes.tableEditor.addChartToTable, addChartToTable);
}
export function* watchUpdateChartHeader() {
  yield takeEvery(actionTypes.tableEditor.updateChartHeader, updateChartHeader);
}
export function* watchUpdateColumnHiddenValue() {
  yield takeEvery(actionTypes.tableEditor.updateColumnHiddenValue, updateColumnHiddenValue);
}
export function* watchPublishTable() {
  yield takeEvery(actionTypes.tableEditor.publishTable, publishTable);
}

export function* watchShowContextMenu() {
  yield takeEvery(actionTypes.tableEditor.showContextMenu, showContextMenu);
}

export function* watchHideContextMenu() {
  yield takeEvery(actionTypes.tableEditor.hideContextMenu, hideContextMenu);
}

export function* watchRedrawTable() {
  yield takeEvery(actionTypes.tableEditor.redrawTable, redrawTable);
}

export function* watchInitEditor() {
  yield takeEvery(actionTypes.tableEditor.initEditor, initEditor);
}

export default function* rootSaga() {
  yield all([
    watchInitEditor(),
    watchLoadInitialPaginatedAction(),
    watchGoToNextPage(),
    watchGoToPreviousPage(),
    watchSearch(),
    watchProcessConditionalFormatting(),
    watchSaveTable(),
    watchLoadTable(),
    watchAddChartToTable(),
    watchUpdateCustomizedArrayProp(),
    watchUpdateChartHeader(),
    watchUpdateColumnHiddenValue(),
    watchPublishTable(),
    watchShowContextMenu(),
    watchHideContextMenu(),
    watchRedrawTable()
  ]);
}
