import { config } from '@visual-elements/packages';
import EditorError from 'pages/ChartEditorPage/components/EditorError';
import EditorPublish from 'pages/ChartEditorPage/components/EditorPublish';
import PanelOptions from 'pages/PackageEditorPage/meta/PanelOptions';
import { loadPackageProjectAction } from 'pages/PackagesPage/actions/packagesPage';
import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import OptionsPanel from 'shared/editor/generic/OptionsPanel';
import OptionsPanelContainer from 'shared/editor/generic/OptionsPanelContainer';
import ProjectContainer from 'shared/editor/generic/ProjectContainer';
import WorkspaceBar from 'shared/editor/generic/WorkspaceBar';
import { resetReduxState } from 'shared/utils/editorHelper';
import { useIsMobile } from '../../shared/utils/mediaHelper';

type PackageEditorProps = {
  showWizard: boolean;
  aggregatedOptions: any;
  history: RouteComponentProps['history'];
  chart: any;
  dataConfig: any;
  isMap: boolean;
};

interface RouteParams {
  projectid: string;
  packageuuid: string;
}

const dimensionsOptions = {
  type: 'auto'
};

const PackageEditor = (props: PackageEditorProps) => {
  const dispatch = useDispatch();
  const containerRef = createRef<HTMLDivElement>();
  const { packageProject, themeData, stockSymbol } = useSelector((state: RootState) => state.packagesPage);
  const { team } = useSelector((state: RootState) => state.profile);
  const { projectid, packageuuid }: RouteParams = useParams();
  const [component, setComponent] = useState<any>();

  useEffect(() => {
    dispatch(loadPackageProjectAction({ uuid: projectid }));

    dispatch(
      setProjectConfigAction({
        inPackagesMode: true,
        tab: 'publish'
      })
    );

    return () => {
      resetReduxState(dispatch);
    };
  }, []);

  useEffect(() => {
    if (packageProject) {
      const packageDetails = config.find((packageDetails) => packageDetails.uuid === packageuuid);
      const selectedProject = (packageDetails?.projects ?? []).find(
        (project) => project.uuid === packageProject.project_uuid
      );

      if (selectedProject) {
        const { component: Component } = selectedProject;
        setComponent(
          <Component
            teamId={team.id}
            theme={themeData}
            dimensions={dimensionsOptions}
            useDynamicResizing={true}
            animations={true}
            stockSymbol={stockSymbol}
            metadata={packageProject.metadata}
          />
        );
      }
    }
  }, [packageProject, themeData, stockSymbol]);

  const isMobile = useIsMobile();

  const myPanelOptions = window.location.href.includes('package/edit/finance/')
    ? PanelOptions
    : PanelOptions.filter((item) => item.id !== 'customize');

  return (
    <div className="story chart-type flex flex-col h-full">
      {isMobile && <EditorPublish />}
      <WorkspaceBar panelOptions={myPanelOptions} />

      <div className="flex w-full h-full flex-col ev-sm:flex-row">
        <OptionsPanelContainer>
          <OptionsPanel options={{ ...props.aggregatedOptions }} panelOptions={myPanelOptions} />
        </OptionsPanelContainer>
        <ProjectContainer>
          <div className="h-[calc(100vh-175px)] w-full flex justify-center">
            <div ref={containerRef} className="canvas-container bg-transparent border-0 h-full w-full">
              {component}
            </div>
          </div>
        </ProjectContainer>
      </div>

      <EditorError />
    </div>
  );
};

export default PackageEditor;
