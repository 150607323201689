import {
  EditorModeOptions,
  LocationMapCustomConfig,
  LocationMapResolution,
  MapViewState
} from '@visual-elements/location-map';
import { LocationMapAggregatedOptions } from '../../../pages/Editor/reducers/locationMapConfigTypes';

export type LocationMapExportMappingOptions = {
  resolution: LocationMapResolution;
  disableControls?: boolean;
};

export function getLocationMapPropsForExporting(
  aggregatedOptions: LocationMapAggregatedOptions,
  exportMappingOptions: LocationMapExportMappingOptions
) {
  const mappingOptions: LocationMapMappingOptions = {
    disableText: false,
    editorMode: {},
    reuseMaps: false,
    disableControls: false,
    interactive: { override: false },
    viewState: { override: false },
    disableAttribution: false,
    ...exportMappingOptions
  };
  return mapToLocationMapProps(aggregatedOptions, mappingOptions);
}

export type LocationMapMappingOptions = {
  viewState: { override: true; value: MapViewState } | { override: false };
  resolution: LocationMapResolution;
  disableText: boolean;
  disableControls: boolean;
  editorMode: Partial<EditorModeOptions>;
  reuseMaps: boolean;
  interactive: { override: true; value: boolean } | { override: false };
  disableAttribution: boolean;
};

export function mapToLocationMapProps(
  aggregatedOptions: LocationMapAggregatedOptions,
  mappingOptions: LocationMapMappingOptions
): LocationMapCustomConfig {
  const props: LocationMapCustomConfig = structuredClone({ ...aggregatedOptions, ...mappingOptions.editorMode });

  if (props.reuseMaps) {
    props.reuseMaps = mappingOptions.reuseMaps;
  }

  if (mappingOptions.viewState.override) {
    props.viewState = {
      ...mappingOptions.viewState.value,
      referenceHeight: aggregatedOptions.viewState?.referenceHeight,
      referenceWidth: aggregatedOptions.viewState?.referenceWidth,
      zoom: aggregatedOptions.viewState?.zoom
    };
  }
  if (mappingOptions.disableControls) {
    delete props.fullScreenControls;
    delete props.geolocateControls;
    delete props.mapNavigation;
  }
  if (mappingOptions.disableText) {
    delete props.title;
    delete props.subtitle;
    delete props.caption;
  }

  if (mappingOptions.interactive.override) {
    props.interactive = mappingOptions.interactive.value;
  }

  if (mappingOptions.resolution) {
    props.resolution = mappingOptions.resolution;
  }

  if (mappingOptions.disableAttribution) {
    props.customAttribution = { enabled: false };
  }
  return props;
}

export type LocationMapPreviewMappingOptions = {
  viewState: { override: true; value: MapViewState } | { override: false };
  zoom: { override: true; value: number } | { override: false };
  border?: string;
};

export function mapToLocationMapPropsPreview(
  aggregatedOptions: LocationMapAggregatedOptions,
  mappingOptions: LocationMapPreviewMappingOptions
): LocationMapCustomConfig {
  const props: LocationMapCustomConfig = { version: aggregatedOptions.version, theme: aggregatedOptions.theme };
  props.viewState = aggregatedOptions.viewState;

  props.layers = structuredClone(aggregatedOptions.layers);
  props.layers.push({ id: 'labels', visible: false });

  props.mapStyle = { borderRadius: mappingOptions.border, minHeight: 'unset' };
  props.reuseMaps = true;
  props.customAttribution = { enabled: false };
  props.disableLoading = true;

  if (mappingOptions.viewState.override) {
    props.viewState = {
      ...mappingOptions.viewState.value,
      referenceHeight: aggregatedOptions.viewState.referenceHeight,
      referenceWidth: aggregatedOptions.viewState.referenceWidth
    };
  }

  return props;
}
